import * as React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const HubungiPage = () => {
  return (
    <Layout>
      <SEO title="Hubungi" />
      <div
        className="layout"
        style={{
          textAlign: "center",
          paddingTop: "80px",
          paddingBottom: "80px",
        }}
      >
        <h1
          style={{
            paddingBottom: "10px",
          }}
        >
          Hubungi Kami
        </h1>
        <p>
          Phone: +62812.938.99.234
          <br />
          Email: hai@purwalenta.com
        </p>
      </div>
    </Layout>
  )
}

export default HubungiPage
